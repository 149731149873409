import {getAisPositions} from "@/api/map.js";
import { Vector as VectorSource} from 'ol/source'
import { Vector as VectorLayer} from 'ol/layer'
import {toStringHDMS} from 'ol/coordinate'
import {fromLonLat} from 'ol/proj'
import { Feature } from 'ol'
import {Point} from 'ol/geom'
import getShipStyle from "@/views/index/map/ship-style";

const source = new VectorSource();
let selectedShip = null;

let getShipTypeName = (value) => {
    if (isNaN(value)) {
        return "其它";
    } else {
        switch (parseInt(value / 10)) {
            case 2 :
                return "地效应船";
            case 3 :
                if (value === 30) {
                    return "渔船";
                }
                break;
            case 4 :
                return "高速船";
            case 5 :
                if (value === 50) {
                    return "引航船";
                }
                break;
            case 6 :
                return "客船";
            case 7 :
                return "货船";
            case 8 :
                return "油船";
            case 10 :
                return "集装箱船";
        }
        if (value === 33 || value === 34 || (value > 53 && value < 60)) {
            return "工作船";
        }
        return "其它"
    }
}
let transformShip = (ship) => {
    ship.id = ship.mmsi || ship.car_no || ship.TRUCK_NUM;
    ship.course = (ship.course !== undefined ? ship.course / 10 : undefined);
    ship.heading = ((ship.heading !== undefined && ship.heading != 511) ? ship.heading : undefined) || ship.direction;
    ship.speed = (ship.speed !== undefined ? ship.speed / 10 : undefined);
    // if (ship.speed !== undefined) {
    //     let speedInKnots = ship.speed /100 * 1.943844; // Convert m/s to knots
    //     ship.speed = parseFloat(speedInKnots.toFixed(1)); // Keep one decimal place
    // }
      
    ship.longitude = ship.longitude / 600000 || ship.lon || ship.LNG;
    ship.latitude = ship.latitude / 600000 || ship.LAT;
    ship.hdms = formateMousePosition(toStringHDMS([ship.longitude, ship.latitude]));
    ship.shipTypeName = getShipTypeName(ship.shipType)
    ship.imgUrls = ship.imgUrl != null ? ship.imgUrl.split(",") : []
    ship.shipLength = ship.shipLength || ship.totalLen * 10 || ship.shipLen * 10
    ship.breadth = ship.breadth || ship.shipWidth || ship.profileWidth * 10
}
let formateMousePosition = (args) => {
    try {
		var index = Number(args.indexOf('N'));
		if(index == -1) {
			index = Number(args.indexOf('S'));
		}
		var strLat = args.substring(0, index + 1);
		var strLon = args.substring(index + 1, args.length);
		var arrLat = strLat.split(" ");
		var arrLon = strLon.split(" ");
		var fen = Number(arrLat[2].substring(0, arrLat[2].length - 1)) / 60;
		fen = Number(fen.toFixed(3));
		fen = Number(arrLat[1].substring(0, arrLat[1].length - 1)) + Number(fen);
		fen = fen.toFixed(3) + arrLat[1].substring(arrLat[1].length - 1, arrLat[1].length);
		var reLat = arrLat[0] + fen + arrLat[3];
		var fen2 = Number(arrLon[3].substring(0, arrLon[3].length - 1)) / 60;
		fen2 = Number(fen2.toFixed(3));
		fen2 = Number(arrLon[2].substring(0, arrLon[2].length - 1)) + Number(fen2.toFixed(3));
		fen2 = fen2.toFixed(3) + arrLon[2].substring(arrLon[2].length - 1, arrLon[2].length);
		var reLon = arrLon[1] + fen2 + arrLon[4];
		return {
			x: reLon,
			y: reLat
		};
	} catch(e) {
		console.log(e);
	}
}
export const layer = (map) => {
    return new VectorLayer({
        source: source,
        zIndex: 99,
        style: (feature, res) => {
            let ship = feature.get('data')
            let text = '' + (ship.shipNameCh || ship.shipNameEn || ship.shipName || ship.mmsi || ship.TRUCK_NUM);
            return getShipStyle(map, feature.getGeometry().getCoordinates(), ship.breadth, ship.shipLength, ship.speed, ship.heading, ship.course, ship.postime, text, '', feature.get('selected'), res, ship.forecast)
        }
    })
};

export const loadShips = (extent) => {
    getAisPositions(extent).then(res => {
        // console.log('API response:', res);
        const data = res.data;
        // console.log('Ship data:', data);
        
        if (Array.isArray(data)) {
            data.forEach(ship => {
                transformShip(ship);
                if (ship.longitude && ship.latitude) {
                    // Check if the ship has a valid ID
                    const shipId = ship.id ? ship.id.toString() : null;
                    if (shipId) {
                        let shipFeature = source.getFeatureById(shipId);
                        if (!shipFeature) {
                            shipFeature = new Feature({
                                geometry: new Point(fromLonLat([ship.longitude, ship.latitude]))
                            });
                            shipFeature.setId(shipId);
                            source.addFeature(shipFeature);
                        } else {
                            shipFeature.getGeometry().setCoordinates(fromLonLat([ship.longitude, ship.latitude]));
                        }
                        shipFeature.set('data', ship);
                        shipFeature.set('type', 'ship');
                    } else {
                        console.warn('Ship object missing ID:', ship);
                    }
                }
            });
        } else {
            console.error('Invalid data format received from API');
        }
    }).catch(error => {
        console.error('Error loading ships:', error);
    });
};

export const selectShip = (id, view) => {
    let shipFeature = source.getFeatureById(id);
    console.log("selectShip", id, shipFeature);
    if (shipFeature) {
        var data = shipFeature.get('data');
        view.setCenter(fromLonLat([data.longitude, data.latitude]));
        view.setZoom(16);
        if (selectedShip) {
            selectedShip.set('selected', false);
        }
        shipFeature.set('selected', true);
        selectedShip = shipFeature;
        return true;
    }
    return false;
};

